<script>
import { ref, computed, watch, nextTick } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import LoyaltyCardForm from './components/card-form.vue'

export default {
  name: 'drawer-loyalty-edit',
  components: {
    LoyaltyCardForm,
    BaseAsidePage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { getLoyaltyCardById, isLoyaltyCardsLoading, loyaltyCards } =
      useLoyaltyCards()

    const loyaltyCard = ref(null)
    const isViewReady = computed(() => {
      return !isLoyaltyCardsLoading.value && loyaltyCard.value
    })
    const pageTitle = computed(
      () => `Edit ${loyaltyCard.value?.name || ''} Card`
    )

    getLoyaltyCardById(props.id).then((card) => {
      if (card) {
        loyaltyCard.value = card
      } else {
        emit('missed')
      }
    })

    watch(loyaltyCards, async () => {
      loyaltyCard.value = await getLoyaltyCardById(props.id)
    })

    return {
      loyaltyCard,
      pageTitle,
      isViewReady,
    }
  },
  methods: {
    setFocus() {
      nextTick(() => {
        this.$refs.baseAsidePage.setFocus()
      })
    },
  },
}
</script>

<template>
  <base-aside-page ref="baseAsidePage" :title="pageTitle">
    <loyalty-card-form
      v-if="isViewReady"
      :card="loyaltyCard"
      :edit="true"
      @updated="$emit('submit', $event)"
    />

    <div v-else class="space-y-[15px]">
      <div class="h-5 animate-pulse rounded-xl bg-strong" />
      <div class="h-5 animate-pulse rounded-xl bg-strong" />
      <div class="h-5 animate-pulse rounded-xl bg-strong" />
    </div>
  </base-aside-page>
</template>
